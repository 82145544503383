<template>
	<v-row>
		<BottomNavigationContainer v-if="menu.length > 1" :menu="menu" />

		<v-col cols="12" class="d-none d-md-block">
			<ToolbarContainer :menu="menu" />
			<v-toolbar elevation="1" height="25" rounded="b">
				<Breadcrumbs />
			</v-toolbar>
		</v-col>

		<v-col cols="12" lg="12">
			<router-view />
		</v-col>
	</v-row>
</template>

<script>
import Breadcrumbs from "../Widgets/Breadcrumbs";
import ToolbarContainer from "../Widgets/ToolbarContainer";
import BottomNavigationContainer from "../Widgets/BottomNavigationContainer";
import { mapState } from "vuex";

export default {
	name: "AnaliseContainer",
	components: { Breadcrumbs, ToolbarContainer, BottomNavigationContainer },
	data: () => ({
		menu: [],
	}),
	methods: {
		montarMenu() {
			let m = [];

			if (this.permissao("analise_liberacao_limite")) {
				m.push({
					titulo: "Limite de crédito",
					titulo_app: "Limite",
					icon: "mdi-text-box-outline",
					to: "/analise",
					tooltip:
						"Libera alçada de limite de crédito para os usuários",
				});
			}

			this.menu = m;
		},
	},
	mounted() {
		this.montarMenu();
	},
	computed: {
		...mapState(["backendUrl"]),
	},
	watch: {
		idcliente: function () {
			this.montarMenu();
		},
	},
};
</script>

<style scoped>
</style>
